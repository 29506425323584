<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-08 14:11:44
 * @LastEditors: Please set LastEditors
 * @Description: 账户提现
 * @FilePath: /java-pc/src/views/member/Header.vue
-->
<template>
  <div class="sld_my_balance">
    <MemberTitle :memberTitle="'账户提现'"></MemberTitle>
    <!-- <div class="top_info flex_row_center_center">
      <div class="total flex_row_center_center">
        {{L['总金额']}}(元)：
        <span class="red">{{Number(balance_info.data.rechargeSum).toFixed(2)}}</span>
      </div>
      <div class="avalibale flex_row_center_center">
        {{L['可用余额']}}(元)：
        <span class="red">{{Number(balance_info.data.balanceAvailable).toFixed(2)}}</span>
      </div>
      <div class="btn_con flex_row_center_center">
        <span class="recharge pointer" @click="toRecharge">{{'申请提现'}}</span>
      </div>
    </div> -->
    <div class="top_header">
      <div class="top_header_top">
        <span>提现列表</span>
        <span @click="requestWith('/member/withdrawalRequest')">申请提现</span>
      </div>
      <div class="top_header_search">
        <div>
          <span>申请单号：</span>
          <input type="text" @input="searchCode" v-model="searchCodes" />
        </div>
        <div>
          <span>申请时间：</span>
          <!-- <el-time-picker
            v-model="value1"
            is-range
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            prefix-icon="none"
          >
          </el-time-picker> -->
          <el-date-picker
            v-model="value1.data"
            type="datetimerange"
            range-separator="--"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            prefix-icon="none"
            @change="dateInp"
          >
          </el-date-picker>
        </div>
        <div>
          <span>状态：</span>
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="(item, index) in options.data"
              :key="index"
              :label="item.label"
              :value="item.value"
              @change="resetuse"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span @click="searchList">搜索</span>
          <span @click="reset">重置</span>
        </div>
      </div>
    </div>
    <!-- 余额明细列表 -->
    <div v-if="current_type == 'balance'" class="list_container">
      <!-- <div class="top_title flex_row_center_center">
        <div class="time flex_row_center_center">{{'申请单号'}}</div>
        <div class="time flex_row_center_center">{{L['申请时间']}}</div>
        <div class="num flex_row_center_center">{{L['收入/支出']}}(元)</div>
        <div class="reason">{{L['变动原因']}}</div>
      </div>
      <div class="list_item flex_row_center_center" v-for="(balanceItem,index) in balance_list.data" :key="index">
        <div class="time flex_row_center_center">{{balanceItem.createTime}}</div>
        <div :class="{num:true,green:true, flex_row_center_center:true,red:balanceItem.state==2}">
          {{balanceItem.state==1?'+':'-'}}{{balanceItem.changeValue}}</div>
        <div class="reason">{{balanceItem.description}}</div>
      </div> -->
      <div class="top_title">
        <div class="request">{{ '申请单号' }}</div>
        <div class="request">{{ '申请时间' }}</div>
        <div class="request">{{ '提现金额' }}(元)</div>
        <!-- <div class="request">{{'手续费'}}(元)</div> -->
        <div class="request">{{ '状态' }}</div>
        <div class="request">{{ '操作' }}</div>
      </div>
      <div
        class="list_item"
        v-for="(balanceItem, index) in balance_list.data"
        :key="index"
      >
        <span>{{ balanceItem.withdrawNo }}</span>
        <span>{{ balanceItem.createTime }}</span>
        <span>{{ balanceItem.withdrawMoney }}</span>
        <span>{{
          balanceItem.state == 1
            ? '未支付'
            : balanceItem.state == 2
            ? '已支付'
            : '提现失败'
        }}</span>
        <span :data-index="balanceItem.withdrawId" @click="goTodetail">{{
          '查看'
        }}</span>
      </div>
      <SldCommonEmpty
        tip="暂无提现明细~"
        totalHeight="587"
        totalWidth="1003"
        v-show="!balance_list.data.length"
      />
    </div>

    <!-- 分页 start -->
    <div class="flex_row_end_center sld_pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="pageData.data.pageSize"
        layout="prev, pager, next, jumper"
        :total="pageData.data.total"
        @prev-click="handlePrevCilickChange"
        @next-click="handleNextCilickChange"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ElMessage, ElPagination } from 'element-plus'
import { getCurrentInstance, onMounted, reactive, ref } from 'vue'
import MemberTitle from '../../../components/MemberTitle'
import SldCommonEmpty from '../../../components/SldCommonEmpty'
export default {
  name: 'Balance',
  components: {
    MemberTitle,
    SldCommonEmpty,
    ElPagination,
  },
  setup() {
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const pageData = reactive({data:{
      current: 1,
      pageSize: 20,
      total: 0,
    }})
    const balance_list = reactive({ data: [] })
    const recharge_list = reactive({ data: [] })
    const balance_info = reactive({ data: {} })
    const current_type = ref('balance') //当前列表显示的数据类型
    const searchCodes = ref('') //搜索的单号
    const status = ref('') //状态
    const value1 = reactive({ data: [] }) //时间选择
    const options = reactive({
      data: [
        {
          value: '未支付',
          label: '未支付',
        },
        {
          value: '已支付',
          label: '已支付',
        },
        {
          value: '提现失败',
          label: '提现失败',
        },
      ],
    })
    //切换
    // const change = (type) => {
    //   pageData.data.current = 1
    //   current_type.value = type
    //   getRequestList()
    // }
    //向前翻页
    const handlePrevCilickChange = () => {
      pageData.data.current--
      console.log(pageData.data.current)
      getRequestList()
    }
    //向后翻页
    const handleNextCilickChange = () => {
      pageData.data.current++
      getRequestList()
    }
    //页数改变
    const handleCurrentChange = (current) => {
      console.log(current)
      pageData.data.current = current
      console.log(pageData.data.current)
      getRequestList()
    }
    //去充值
    // const toRecharge = () => {
    //   router.push({
    //     path: '/member/recharge'
    //   })
    // }

    //申请单号搜索
    const searchCode = () => {}
    const requestWith = (path) => {
      router.push(path)
    }
    //获取提现列表
    const getRequestList = async () => {
      const data = {
        current: pageData.data.current,
        pageSize: pageData.data.pageSize,
      }
      let ret = await proxy.$get('/v3/member/front/memberWithdraw/list', {
        ...data,
      })
      balance_list.data = ret.data.list
      pageData.data.total = ret.data.pagination.total
    }
    //跳转到查看详情页面
    const goTodetail = (e) => {
      console.log(e)
      const id = e.target.dataset.index
      router.push({
        path: '/member/withdrawalDetailed',
        query: {
          id,
        },
      })
    }

    //重置按钮
    const reset = () => {
      status.value = searchCodes.value = value1.data = ''
      getRequestList()
    }
    //获取下拉框选中状态在页面展示
    const resetuse = (val) => {
      status.value = val
    }
    //获取时间在页面展示
    const dateInp = (val) => {
      console.log(val)
      value1.data = val
      console.log(value1.data)
    }
    onMounted(() => {
      getRequestList()
    })

    //搜索
    const searchList = async () => {
      let startTime = new Date(value1.data[0])
      startTime =
        startTime.getFullYear() +
        '-' +
        (startTime.getMonth() + 1) +
        '-' +
        startTime.getDate() +
        ' ' +
        startTime.getHours() +
        ':' +
        startTime.getMinutes() +
        ':' +
        startTime.getSeconds()
      let endTime = new Date(value1.data[1])
      endTime =
        endTime.getFullYear() +
        '-' +
        (endTime.getMonth() + 1) +
        '-' +
        endTime.getDate() +
        ' ' +
        endTime.getHours() +
        ':' +
        endTime.getMinutes() +
        ':' +
        endTime.getSeconds()
      console.log(startTime)
      console.log(endTime)
      const data = {
        current: pageData.data.current,
        pageSize: pageData.data.pageSize,
        endTime,
        startTime,
        state: status.value == '未支付' ? 1 : status.value == '已支付' ? 2 : 3,
        withdrawNo: searchCodes.value,
      }
      let ret = await proxy.$get('/v3/member/front/memberWithdraw/list', {
        ...data,
      })
      console.log(ret)
      balance_list.data = ret.data.list
    }

    return {
      L,
      pageData,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      balance_list,
      // change,
      current_type,
      recharge_list,
      balance_info,
      // toRecharge,
      searchCode,
      requestWith,
      goTodetail,
      searchCodes,
      options,
      reset,
      resetuse,
      status,
      dateInp,
      value1,
      searchList,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/withdrawal.scss';

.sld_pagination {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>